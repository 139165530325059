<template>
    <div class="frontBackaAppraisal_tea">
        <div class="filtrate">
            <el-select v-model="termValue" placeholder="请选择" @change="termChange">
                <el-option v-for="(item, index) in termList" :key="index" :label="item.termName" :value="item.key">
                </el-option>
            </el-select>
            <el-select v-model="reportValue" placeholder="请选择" @change="reportChange">
                <el-option v-for="item in reportList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
            </el-select>
            <el-select v-model="monthVal" placeholder="请选择" @change="monthTypeChange" v-if="reportType == 6">
                <el-option v-for="item in monthList" :key="item.value" :label="item.name" :value="item.id">
                </el-option>
            </el-select>
            <el-select v-model="weekValue" placeholder="请选择" @change="weekValTypeChange" v-if="reportType == 7">
                <el-option v-for="(item, index) in weekList" :key="index" :label="item.name" :value="item.id">
                </el-option>
            </el-select>
            <el-select v-model="gradeValue" placeholder="请选择" @change="gradeChange">
                <el-option v-for="item in gradeList" :key="item.value" :label="item.name"
                    :value="{ label: item.name, value: item.bindId }">
                </el-option>
            </el-select>
            <div class="export flex-align">
                <div class="wrap flex-align" @click="reportValue == 7 ? getexportExcel_week() : getexportExcel()">
                    <img src="../../assets/newReport/export.png" alt="">
                    导出数据
                </div>
            </div>
        </div>
        <div class="type_title flex-column-center">
            <h1>{{ schoolName }}{{ gradeValue }}{{ reportType == 4 ? '前测' : reportType == 5 ? '后测' : reportType == 5
                ? '月测' : '周测' }}报告</h1>
            <h2 v-if='reportType == 4 || reportType == 5'>{{ semester_name }}</h2>
            <h2 v-else-if="reportType == 6">{{ monthName }}</h2>
            <h2 v-else="reportType == 7">{{ weekName }}</h2>
        </div>
        <!-- 前后测报告 -->
        <div class="overall_situation" v-if='reportType == 4 || reportType == 5'>
            <template v-if="hide_school()">
                <div class="empty">
                    <el-empty description="暂无报告"></el-empty>
                </div>
            </template>
            <template v-else>
                <h1 class="First_level_heading">1.整体情况</h1>
                <div class="participation">
                    <div class="broad_heading flex-align-around">
                        <div class="Participate_assessment nature">
                            <div class="broad_title">本次测评参与</div>
                            <div class="broad_tcount">
                                <span>{{ userNum }}</span><span>人</span>
                            </div>
                            <div class="proportion">本年级参与率{{ gradeRate }}%</div>
                        </div>
                        <div class="hold_posture nature">
                            <div class="broad_title">握姿错误</div>
                            <div class="broad_tcount">
                                <span>{{ wrongGrip }}</span><span>人</span>
                            </div>
                            <div class="proportion">本年级{{ gradeWrongGrip }}人</div>
                        </div>
                        <div class="sit_posture nature">
                            <div class="broad_title">坐姿错误</div>
                            <div class="broad_tcount">
                                <span>{{ wrongSitPosition }}</span><span>人</span>
                            </div>
                            <div class="proportion">本年级{{ gradeWrongSitPosition }}人</div>
                        </div>
                        <div class="excellent_rate nature">
                            <div class="broad_title">{{ table_type == 1 ? '优秀率' : '优良率' }}</div>
                            <div class="broad_tcount">
                                <span>{{ excellentRate }}</span><span>%</span>
                            </div>
                            <div class="proportion">本年级{{ gradeExcellentRate }}%</div>
                        </div>
                    </div>
                    <div class="writing_habit_sitting">
                        <!-- //标题 -->
                        <div class="Secondary_heading">
                            <div class="vertical_grain_icon"></div> <span class="title">书写习惯-坐姿</span>
                        </div>
                        <div class="substance">
                            <div class="substance_details flex-between">
                                <div class="sitting_position_table">
                                    <img src="../../assets/newReport/sitting.png" alt="">
                                    <Short_form_tea :construction="sitting_construction" :tabledata="sitting_Data"
                                        :high="Height1" :row_high="row_height1"></Short_form_tea>
                                </div>
                                <div class="sitting_position_graph" id="sitting_position_graph"></div>
                            </div>
                            <div class="sitting_conclusion conclusion">
                                <span class="prefix_title">结论1</span>
                                针对坐姿，有<span class="Comment_highlight">{{ sitting_Data_mistake }}</span>名学生出现坐姿错误，占全校的<span
                                    class="Comment_highlight">{{ sitting_wrongNumSchoolRatio }}%</span>。
                            </div>
                        </div>
                    </div>
                    <div class="writing_habit_sitting">
                        <!-- //标题 -->
                        <div class="Secondary_heading">
                            <div class="vertical_grain_icon"></div> <span class="title">书写习惯-握笔姿势</span>
                        </div>
                        <div class="substance">
                            <div class="substance_details flex-between">
                                <div class="sitting_position_table">
                                    <img src="../../assets/newReport/hold.png" alt="">
                                    <Short_form_tea :construction="hold_construction" :tabledata="hold_Data" :high="Height1"
                                        :row_high="row_height1"></Short_form_tea>
                                </div>
                                <div class="sitting_position_graph" id="hold_position_graph"></div>
                            </div>
                            <div class="sitting_conclusion conclusion">
                                <span class="prefix_title">结论2</span>
                                针对握笔姿势，有<span class="Comment_highlight">{{ hold_Data_mistake
                                }}</span>名学生使用了错误的握笔姿势，占全校的<span class="Comment_highlight">{{ hold_wrongNumSchoolRatio
}}%</span>。
                            </div>
                        </div>
                    </div>
                    <div class="writing_habit_sitting">
                        <div class="Secondary_heading">
                            <div class="vertical_grain_icon"></div> <span class="title">书写结果判断握笔姿势</span>
                        </div>
                        <div class="result_judgment">
                            <img :src="sitimg ? require('../../assets/newReport/wobi2.png') : require('../../assets/newReport/wobi1.png')"
                                alt="">
                            <div class="result_judgment_btn flex-align-center" @click="sitimg = !sitimg">换一组查看</div>
                        </div>
                    </div>
                    <score_Proportion_tea_old ref="score_Proportion_tea_old" v-if="table_type == 1" :old_data="old_data">
                    </score_Proportion_tea_old>
                    <score_Proportion_tea_new ref="score_Proportion_tea_new" :new_data="new_data" v-else>
                    </score_Proportion_tea_new>
                    <div class="writing_habit_sitting" v-if="workList.length != 0">
                        <div class="outstanding_example">
                            <div class="outstanding_tit flex-align-center">
                                <img src="../../assets/newReport/specimen.png" alt="" v-if="table_type == 1" />
                                <img src="../../assets/newReport/specimen2.png" alt="" v-else />
                            </div>
                            <div class="works flex-align-center">
                                <div class="el_image_box flex-align-center">
                                    <el-image :src="item" v-for="(item, index) in workList" :key="index"
                                        :preview-src-list="workList">
                                    </el-image>
                                </div>
                            </div>
                            <div class="changeset flex-align-center ">
                                <div class="changeset_btn flex-align-center">换一组查看</div>
                            </div>
                        </div>
                    </div>
                    <div class="writing_habit_sitting">
                        <div class="Secondary_heading">
                            <div class="vertical_grain_icon"></div> <span class="title">本班{{ table_type ==
                                1 ? '优秀' : '优良' }}学生名单</span>
                        </div>
                        <div class="student_list_box">
                            <div class="student_list">
                                <Short_form_tea :construction="student_list_construction" :tabledata="student_list_Data"
                                    :high="Height2" :row_high="row_height2"></Short_form_tea>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
        <!-- 月测报告 -->
        <div class="overall_situation" v-else-if="reportType == 6">
            <template v-if="isEmpty">
                <div class="empty">
                    <el-empty description="暂无报告"></el-empty>
                </div>
            </template>
            <template v-else>
                <h1 class="First_level_heading">1.整体情况</h1>
                <div class="participation">
                    <div class="broad_heading flex-align-around">
                        <div class="Participate_assessment nature">
                            <div class="broad_title">本次测评参与</div>
                            <div class="broad_tcount">
                                <span>{{ userNum }}</span><span>人</span>
                            </div>
                            <div class="proportion">本年级参与率{{ gradeRate }}%</div>
                        </div>
                        <div class="hold_posture nature">
                            <div class="broad_title">{{ table_type == 1 ? '优秀+' : '优秀' }}</div>
                            <div class="broad_tcount">
                                <span>{{ wrongGrip }}</span><span>人</span>
                            </div>
                            <div class="proportion">占本年级的{{ gradeWrongGrip }}%</div>
                        </div>
                        <div class="sit_posture nature">
                            <div class="broad_title">待提升</div>
                            <div class="broad_tcount">
                                <span>{{ wrongSitPosition }}</span><span>人</span>
                            </div>
                            <div class="proportion">本年级{{ gradeWrongSitPosition }}人</div>
                        </div>
                        <div class="excellent_rate nature">
                            <div class="broad_title">{{ table_type == 1 ? '优秀率' : '优良率' }}</div>
                            <div class="broad_tcount">
                                <span>{{ excellentRate }}</span><span>%</span>
                            </div>
                            <div class="proportion">本年级{{ gradeExcellentRate }}%</div>
                        </div>
                    </div>
                    <div class="writing_habit_sitting">
                        <!-- //标题 -->
                        <div class="flex-between">
                            <div>
                                <div class="Secondary_heading">
                                    <div class="vertical_grain_icon"></div> <span class="title">本班单项成绩等级占比</span>
                                </div>
                                <div class="substance">
                                    <div class="substance_details flex-between">
                                        <!-- :high="Height1" :row_high="row_height3" -->
                                        <div class="sitting_position_table">
                                            <Short_form_tea :construction="month_construction" :tabledata="month_Data"
                                                type="月测"></Short_form_tea>
                                            <div class="sitting_position_graph" id="month_class_proportion"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="Secondary_heading">
                                    <div class="vertical_grain_icon"></div> <span class="title">本班学生测评等级列表</span>
                                </div>
                                <div class="student_list_box2">
                                    <div class="student_list2">
                                        <Short_form_tea :construction="monthstudent_list_construction" :high="HeightList"
                                            :row_high="HeightList_row" :tabledata="month_student_list_Data">
                                        </Short_form_tea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="sitting_conclusion conclusion">
                            <span class="prefix_title">结论1</span>
                            本班学生综合成绩来看,平均成绩<span class="Comment_highlight">{{ maxClassLevel }}</span>等级的学生居多,
                            {{ table_type == 1 ? '优秀率' : '优良率' }}为<span class="Comment_highlight">{{ maxClassProp }}%</span>
                            <span v-if="maxgradeProp != 0 && maxgradeProp != 0">，
                                <span class="Comment_highlight">{{ gradeCompare }}</span>年级{{ table_type == 1 ? '优秀率' :
                                    '优良率' }}。
                            </span>
                            <span v-else>。</span>
                        </div>
                    </div>
                    <singleSubjectAvg_tea_month_old ref="singleSubjectAvg_tea_month_old" v-if="table_type == 1">
                    </singleSubjectAvg_tea_month_old>
                    <singleSubjectAvg_tea_month_new ref="singleSubjectAvg_tea_month_new" :userNum="userNum"
                        :gradeValue="gradeValue" type="月测" v-else>
                    </singleSubjectAvg_tea_month_new>
                    <div class="writing_habit_sitting" v-if="workList.length != 0">
                        <div class="outstanding_example">
                            <div class="outstanding_tit flex-align-center">
                                <img src="../../assets/newReport/specimen.png" alt="" v-if="table_type == 1" />
                                <img src="../../assets/newReport/specimen2.png" alt="" v-else />
                            </div>
                            <div class="works flex-align-center">
                                <div class="el_image_box flex-align-center">
                                    <el-image :src="workurl"></el-image>
                                </div>
                            </div>
                            <div class="changeset flex-align-center ">
                                <div class="changeset_btn flex-align-center" @click="stochastic">换一组查看</div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
        <weekTest_tea :termValue="termValue" :weekValue="weekValue" :weekName="weekName" :gradeId="gradeId"
            :gradeValue="gradeValue" :isEmpty="isEmpty" v-else></weekTest_tea>
    </div>
</template>
<script>
import Short_form_tea from './appraisalCharts/Short_form_tea.vue'
import score_Proportion_tea_old from './score_Proportion_tea_old.vue'
import score_Proportion_tea_new from './score_Proportion_tea_new.vue'
import singleSubjectAvg_tea_month_old from './singleSubjectAvg_tea_month_old.vue'
import singleSubjectAvg_tea_month_new from './singleSubjectAvg_tea_month_new.vue'
import weekTest_tea from './weekTest_tea.vue'

export default {
    //import引入组件才能使用
    components: {
        Short_form_tea,
        score_Proportion_tea_old,
        score_Proportion_tea_new,
        singleSubjectAvg_tea_month_old,
        singleSubjectAvg_tea_month_new,
        weekTest_tea
    },
    props: {},
    data() {
        return {
            schoolName: '',
            semester_name: '',
            //顶部筛选-学期/报告类型
            reportList: [{
                value: 4,
                label: '前测报告'
            }, {
                value: 5,
                label: '后测报告'
            }, {
                value: 6,
                label: '月测报告'
            },
            {
                value: 7,
                label: '周测报告'
            }
            ],
            reportValue: 4,
            //书写习惯-坐姿
            sitting_construction: [{
                fieldName: 'date',
                title: '学期'
            },
            {
                fieldName: 'wrongNum',
                title: '错误坐姿人数'
            },
            {
                fieldName: 'ratio',
                title: '坐姿错误比例'
            },
            ],
            sitting_Data: [],
            // 坐姿错误人数
            sitting_Data_mistake: 0,
            // 占全校
            sitting_wrongNumSchoolRatio: 0,
            //书写习惯-握笔
            hold_construction: [{
                fieldName: 'date',
                title: '学期'
            },
            {
                fieldName: 'wrongNum',
                title: '握笔错误人数'
            },
            {
                fieldName: 'ratio',
                title: '握笔错误比例'
            },
            ],
            hold_Data: [],
            // 坐姿错误人数
            hold_Data_mistake: 0,
            // 占全校
            hold_wrongNumSchoolRatio: 0,
            // Height1: '212px',
            Height1: '136.5px',
            HeightList: '635.7px',
            HeightList_row: '42.38px',
            // row_height1: '70px',
            row_height1: '45px',
            sitimg: true,
            //握笔错误人数
            // 标题
            hold_ratio_Data: [],
            // 结论
            // 章法占格
            layout: '',
            // 结构组合
            structure: '',
            // 笔画形态
            form: '',
            k1: '',
            k2: '',
            k3: '',
            // 作品完整度
            complete: '',
            Term_comparison_Data: [],
            dimension_Data: [],
            dimension_Data_layout1: '',
            dimension_Data_layout2: '',
            dimension_Data_structure1: '',
            dimension_Data_structure2: '',
            dimension_Data_form1: '',
            dimension_Data_form2: '',
            dimension_Data_complete1: '',
            dimension_Data_complete2: '',
            //箭头类型
            imgUrl: {
                imgUrl1: require('@/assets/newReport/greent.png'),
                imgUrl2: require('@/assets/newReport/redx.png'),
                imgUrl3: require('@/assets/newReport/or_r.png'),
            },
            student_list_construction: [{
                fieldName: 'name',
                title: '姓名'
            },
            {
                fieldName: 'className',
                title: '年级'
            },
            {
                fieldName: 'gradeName',
                title: '班级'
            },
            ],
            student_list_Data: [],
            // Height2: '600px',
            Height2: '',
            row_height2: '40px',
            row_height3: '20px',
            //学年
            termList: [],
            termValue: '',
            currentTerm: '',
            //班级
            gradeList: [],
            gradeValue: '',
            gradeId: '',
            //整体情况
            //测评人数
            userNum: 0,
            //本年级参与率
            gradeRate: 0,
            //握姿错误
            wrongGrip: 0,
            //本年级握姿错误
            gradeWrongGrip: 0,
            //坐姿错误
            wrongSitPosition: 0,
            //本年级坐姿错误
            gradeWrongSitPosition: 0,
            //优秀率
            excellentRate: 0,
            //本年级优秀率
            gradeExcellentRate: 0,
            limit: 2,
            pageSize: 1,
            workList: [require('../../assets/newReport/demo.jpg'), require('../../assets/newReport/demo.jpg')],
            reportType: 4, // 报告类型:4前测 5后测 6月测
            reportCode: 'LZ3-C-00', // 报告code:LZ3-C-00前测 LZ3-C-01后测
            monthVal: '',
            monthName: '',
            monthList: [],
            //周测
            weekValue: '',
            weekName: '',
            weekList: [],
            // 月测-本班单项成绩等级占比表
            month_construction: [{
                fieldName: 'integrationLevel',
                title: '等级'
            },
            {
                fieldName: 'classPropRate',
                title: '本班人数占比'
            },
            {
                fieldName: 'gradePropRate',
                title: '年级平均人数占比'
            }],
            month_Data: [],
            monthstudent_list_construction: [{
                fieldName: 'className',
                title: '班级'
            }, {
                fieldName: 'stuName',
                title: '姓名'
            },
            {
                fieldName: 'levelDesc',
                title: '等级'
            }],
            month_student_list_Data: [],
            gradeCompare: '',
            maxClassProp: 0,
            maxgradeProp: 0,
            maxClassLevel: '',
            AI_Data: [],
            dimension_Data2: [],
            workurl: '',
            isEmpty: false,
            table_type: null,
            new_data: null,
            old_data: null,
            schoolIdArr: ['181166', '1176', '181095', '181165', '1172', '181164', '1175', '181097', '1154'],
        }
    },
    // 计算属性
    computed: {},
    // 监听data中的数据变化
    watch: {},
    // 方法集合
    methods: {
        //获取学年学期列表
        async getTermList() {
            let resData = await this.$Api.DataScreen.queryTermList();
            this.termList = resData.data;
            if (this.$route.query.fromType == 'multiRole') {
                if (this.$route.query.date) {
                    this.termValue = Number(this.$route.query.date);
                    const index = this.termList.findIndex(item => item.key == Number(this.$route.query.date));
                    this.currentTerm = this.termList[index].termName;
                    this.semester_name = this.termList[index].termName;
                } else {
                    this.termValue = this.termList[0].key;
                    this.currentTerm = this.termList[0].termName;
                    this.semester_name = this.termList[0].termName;
                }

            } else {
                this.termValue = this.termList[0].key;
                this.currentTerm = this.termList[0].termName;
                this.semester_name = this.termList[0].termName;
            }
            this.getIsNewDIm();

        },
        //获取班级
        async gradequeryOrgInfo() {
            let data = {
                schoolId: this.$route.query.schoolId,
                termKey: this.termValue
            }
            let resData = await this.$Api.DataScreen.queryTeacherClassSelect(data);
            resData.data.forEach(item => {
                item.sonOrgList.forEach(item_son => {
                    item_son.className = item.name;
                })
            });
            this.gradeList = resData.data.reduce((accumulator, currentObj) => {
                return [...accumulator, ...currentObj.sonOrgList];
            }, []);
            this.className = this.gradeList[0].className;
            this.gradeValue = this.gradeList[0].name;
            this.gradeId = this.gradeList[0].bindId;
            if (this.reportType == 6) {
                this.month_report();
            } else if (this.reportType == 7) {
                this.weekParticipation();
            } else {
                this.gather();
            }
        },
        //整体数据
        async getparticipation() {
            let data;
            let resData;
            if (this.reportType == 6) {
                await this.monthParticipation();
                data = {
                    schoolId: this.$route.query.schoolId,
                    termKey: this.termValue,
                    testId: this.monthVal,
                    examType: 4,
                    classId: this.gradeId,
                }
                resData = this.table_type == 1 ? await this.$Api.Report.overallSituation(data) : await this.$Api.Report.getoverallSituation_month(data);
                this.userNum = resData.data.classTakeNum;
                this.gradeRate = resData.data.gradeJoinRate;
                this.wrongGrip = resData.data.goodP;
                this.gradeWrongGrip = resData.data.gradeGoodPRateClass;
                this.wrongSitPosition = resData.data.bad;
                this.gradeWrongSitPosition = resData.data.gradeBad;
                this.excellentRate = resData.data.goodRate;
                this.gradeExcellentRate = resData.data.gradeGoodRate;


            } else {
                data = {
                    classId: this.gradeId,
                    termKey: this.termValue,
                    testType: this.reportType,
                    testTypeCode: this.reportCode
                }
                resData = await this.$Api.Report.getparticipation(data);
                this.userNum = resData.data.userNum;
                this.gradeRate = resData.data.gradeRate;
                this.wrongGrip = resData.data.wrongGrip;
                this.gradeWrongGrip = resData.data.gradeWrongGrip;
                this.wrongSitPosition = resData.data.wrongSitPosition;
                this.gradeWrongSitPosition = resData.data.gradeWrongSitPosition;
                this.excellentRate = resData.data.excellentRate;
                this.gradeExcellentRate = resData.data.gradeExcellentRate;
            }

        },
        //书写习惯-坐姿
        async getsitPosition() {
            let data = {
                classId: this.gradeId,
                termKey: this.termValue,
                testType: this.reportType,
                testTypeCode: this.reportCode
            }
            let resData = await this.$Api.Report.getsitPosition(data);
            resData.data.lastTerm[0].ratio = resData.data.lastTerm[0].ratio + '%';
            resData.data.thisTerm[0].ratio = resData.data.thisTerm[0].ratio + '%';
            this.sitting_Data = [...resData.data.lastTerm, ...resData.data.thisTerm];
            this.sitting_Data[0].date = '上学期';
            this.sitting_Data[1].date = '本学期';
            // 本学期坐姿错误数
            let mistake_count = this.sitting_Data[1].wrongNum;
            // 本学期坐姿正确数
            let correct_count = this.sitting_Data[1].correctNum;
            this.sitting_Data_mistake = mistake_count;
            // 占全校百分比
            this.sitting_wrongNumSchoolRatio = resData.data.wrongNumSchoolRatio;
            this.sitting_position_graph(mistake_count, correct_count)
        },
        //书写习惯-握姿
        async getholdAPenPosture() {
            let data = {
                classId: this.gradeId,
                termKey: this.termValue,
                testType: this.reportType,
                testTypeCode: this.reportCode
            }
            let resData = await this.$Api.Report.getholdAPenPosture(data);
            resData.data.lastTerm[0].ratio = resData.data.lastTerm[0].ratio + '%';
            resData.data.thisTerm[0].ratio = resData.data.thisTerm[0].ratio + '%';
            this.hold_Data = [...resData.data.lastTerm, ...resData.data.thisTerm];
            this.hold_Data[0].date = '上学期';
            this.hold_Data[1].date = '本学期';
            // 本学期坐姿错误数
            let mistake_count = this.hold_Data[1].wrongNum;
            // 本学期坐姿正确数
            let correct_count = this.hold_Data[1].correctNum;
            this.hold_Data_mistake = mistake_count;
            // 占全校百分比
            this.hold_wrongNumSchoolRatio = resData.data.wrongNumSchoolRatio;
            this.hold_position_graph(mistake_count, correct_count)
        },
        //本班单项成绩等级占比
        async getstudentLvRatioCompare() {
            let data = {
                classId: this.gradeId,
                termKey: this.termValue,
                testType: this.reportType,
                testTypeCode: this.reportCode
            }
            let resData = await this.$Api.Report.getstudentLvRatioCompare(data);
            this.$refs.score_Proportion_tea_old.receiveData(resData, this.gradeValue, this.userNum, this.table_type);
        },
        //本班单项成绩等级占比
        async getstudentLvRatioCompareV2() {
            let test_type = this.reportType == 4 ? 1 : this.reportType == 5 ? 2 : 4;
            let data = {
                classId: this.gradeId,
                termKey: this.termValue,
                testType: test_type,
                examId: this.monthVal
            }
            let resData = await this.$Api.Report.getstudentLvRatioCompareV2(data);
            this.$refs.score_Proportion_tea_new.receiveData(resData, this.gradeValue, this.userNum, this.table_type);
        },
        judge_type_icon(value1, value2) {
            if (value1 > value2) {
                return 'imgUrl1'
            } else if (value1 < value2) {
                return 'imgUrl2'
            } else {
                return 'imgUrl3'
            }

        },
        //一级维度成绩
        async getoneDScoreCompare() {
            let data = {
                classId: this.gradeId,
                termKey: this.termValue,
                testType: this.reportType,
                testTypeCode: this.reportCode
            }
            let resData = await this.$Api.Report.getoneDScoreCompare(data);
            this.$refs.score_Proportion_tea_old.receiveData_dimension(resData);
        },
        //一级维度成绩-班级
        async getoneDScoreCompareV2() {
            let test_type = this.reportType == 4 ? 1 : this.reportType == 5 ? 2 : 4;
            let data = {
                classId: this.gradeId,
                termKey: this.termValue,
                testType: test_type,
                examId: this.monthVal
            }
            let resData = await this.$Api.Report.getoneDScoreCompareV2(data);
            this.$refs.score_Proportion_tea_new.receiveData_dimension(resData, this.gradeValue, this.table_type);
        },
        //判断水平
        judgeLevel(value) {
            if (this.table_type == 1) {
                if (value >= 90) {
                    return '优秀+'
                } else if (value >= 80 && value < 90) {
                    return '优秀-'
                } else if (value >= 60 && value < 80) {
                    return '良好'
                } else {
                    return '待提升'
                }
            } else {
                if (value >= 85) {
                    return '优秀';
                } else if (value >= 70 && value < 85) {
                    return '良好';
                } else if (value >= 60 && value < 70) {
                    return '合格';
                } else {
                    return '待提升';
                }
            }

        },
        average(value1, value2) {
            return value1 > value2 ? '高于' : value1 < value2 ? '低于' : '等于'
        },
        //“优秀+”作品样例 分页
        async getlevel4WorkPage() {
            this.workList = [];
            let data;
            let resData;
            //月测
            if (this.reportType == 6) {
                await this.monthParticipation();
                if (this.table_type == 1) {

                    data = {
                        schoolId: this.$route.query.schoolId,
                        termKey: this.termValue,
                        testId: this.monthVal,
                        examType: 4,
                        classId: this.gradeId,
                    }
                    resData = await this.$Api.Report.getWorksSch(data);
                    resData.data.records.map(item => {
                        this.workList.push(item.filePath)
                    });
                    this.workurl = this.workList[0];
                } else {
                    data = {
                        schoolId: this.$route.query.schoolId,
                        termKey: this.termValue,
                        testId: this.monthVal,
                        examType: 4,
                        classId: this.gradeId,
                    }
                    resData = await this.$Api.Report.getWorksSchV2(data);
                    resData.data.records.map(item => {
                        this.workList.push(item.filePath)
                    });
                    this.workurl = this.workList[0];
                }

            } else {
                //前后测
                data = {
                    classId: this.gradeId,
                    termKey: this.termValue,
                    size: this.limit,
                    current: this.pageSize,
                    testType: this.reportType,
                    testTypeCode: this.reportCode
                }
                resData = await this.$Api.Report.getlevel4WorkPage(data);
                if (resData.data.records.length < this.limit) {
                    this.current = 1;
                } else {
                    this.current += 1;
                }
                resData.data.records.map(item => {
                    this.workList.push(item.fileUrl)
                })
            }


        },
        stochastic() {
            const randomIndex = Math.floor(Math.random() * this.workList.length);
            this.workurl = this.workList[randomIndex];
        },
        //本班优秀 学生名单 旧
        async geteliteStudent() {
            this.workList = [];
            let that = this;
            let data = {
                classId: this.gradeId,
                termKey: this.termValue,
                testType: this.reportType,
                testTypeCode: this.reportCode
            }
            let resData = await this.$Api.Report.geteliteStudent(data);
            this.student_list_Data = resData.data.map(item => {
                return {
                    ...item,
                    className: that.className,
                    gradeName: that.gradeValue
                }
            })
        },
        //本班优秀 学生名单 新
        async geteliteStudentV2() {
            this.workList = [];
            let that = this;
            let testType = this.reportValue == 4 ? 1 : this.reportValue == 5 ? 2 : 4;
            let data = {
                classId: this.gradeId,
                termKey: this.termValue,
                examType: testType,
            }
            let resData = await this.$Api.Report.geteliteStudentV2(data);
            this.student_list_Data = resData.data.map(item => {
                return {
                    ...item,
                    className: that.className,
                    gradeName: that.gradeValue
                }
            })
        },
        getexportExcel() {
            let data = {
                classId: this.gradeId,
                termKey: this.termValue,
                examType: this.reportType == 4 ? 1 : this.reportType == 5 ? 2 : 4,
                schoolId: this.$route.query.schoolId
            }
            if (this.reportType == 6) {
                data.testId = this.monthVal
            }
            if (this.table_type == 1) {
                this.$Api.Report.getexportExcel(data)
                    .then(res => {
                        const link = document.createElement('a')
                        const blob = new Blob([res], {
                            type: 'application/x-msdownload'
                        });
                        const fileName = this.reportType == 6 ? '月测报告-教师' : '前后测报告-教师';
                        link.style.display = 'none';
                        link.href = URL.createObjectURL(blob);
                        document.body.appendChild(link);
                        link.download = fileName + '.xlsx';
                        link.click();
                        document.body.removeChild(link);
                    })
                    .catch(err => {
                        console.log('导出失败:', err)
                    })
            } else {
                this.$Api.Report.getexportExcelV2(data)
                    .then(res => {
                        const link = document.createElement('a')
                        const blob = new Blob([res], {
                            type: 'application/x-msdownload'
                        });
                        const fileName = this.reportType == 6 ? '月测报告-教师' : '前后测报告-教师';
                        link.style.display = 'none';
                        link.href = URL.createObjectURL(blob);
                        document.body.appendChild(link);
                        link.download = fileName + '.xlsx';
                        link.click();
                        document.body.removeChild(link);
                    })
                    .catch(err => {
                        console.log('导出失败:', err)
                    })
            }

        },
        getexportExcel_week() {
            let data = {
                classId: this.gradeId,
                termKey: this.termValue,
                examType: 5,
                testId: this.weekValue,
                schoolId: this.$route.query.schoolId
            }
            this.$Api.Report.getexportExcelV2(data)
                .then(res => {
                    const link = document.createElement('a')
                    const blob = new Blob([res], {
                        type: 'application/x-msdownload'
                    });
                    const fileName = '周测报告-教师';
                    link.style.display = 'none';
                    link.href = URL.createObjectURL(blob);
                    document.body.appendChild(link);
                    link.download = fileName + '.xlsx';
                    link.click();
                    document.body.removeChild(link);
                })
                .catch(err => {
                    console.log('导出失败:', err)
                })


        },
        //切换学期
        termChange(val) {
            const index = this.termList.findIndex(item => item.key == val);
            this.currentTerm = this.termList[index].termName;
            this.semester_name = this.termList[index].termName;
            this.getIsNewDIm();
        },
        //切换班级
        gradeChange(item) {
            this.gradeValue = item.label;
            this.gradeId = item.value;
            const index = this.gradeList.findIndex(i => i.name == item.label);
            this.className = this.gradeList[index].className;
            if (this.reportType == 6) {
                this.month_report();
            } else if (this.reportType == 7) {
                this.weekParticipation();
            } else {
                this.gather();
            }
        },
        // 前后测报告请求集合
        gather() {
            // 整体数据
            this.getparticipation();
            // 书写习惯-坐姿
            this.getsitPosition();
            // 书写结果判断握笔姿势
            this.getholdAPenPosture();

            if (this.table_type == 1) {
                // 本班单项成绩等级占比 旧版
                this.getstudentLvRatioCompare();
                // 一级维度成绩 旧版
                this.getoneDScoreCompare();
                //本班优秀名单
                this.geteliteStudent();
            } else {
                // 本班单项成绩等级占比 新版
                this.getstudentLvRatioCompareV2();
                // 一级维度成绩 新版
                this.getoneDScoreCompareV2();
                //本班优秀名单
                this.geteliteStudentV2();

            }
            //作品样例
            this.getlevel4WorkPage();
        },
        //书写习惯-坐姿
        sitting_position_graph(value1, value2) {
            this.$echarts.init(document.getElementById("sitting_position_graph")).dispose();
            let myChart = this.$echarts.init(
                document.getElementById("sitting_position_graph")
            );
            myChart.setOption({
                color: ['#48c5ec', '#72d07e'],
                title: {
                    text: '坐姿',
                    top: '4%',
                    left: 'center',
                    textStyle: {
                        fontSize: this.graphicRatio(14),
                    },
                },
                legend: {
                    top: '12%',
                    left: 'center',
                    itemWidth: this.graphicRatio(22),
                    itemHeight: this.graphicRatio(14),
                    textStyle: {
                        fontSize: this.graphicRatio(12),
                    }
                },
                series: [
                    {
                        name: '书写习惯-坐姿',
                        type: 'pie',
                        radius: '50%',
                        label: {
                            formatter: "{b} {c} 人",
                            fontSize: this.graphicRatio(14),
                        },
                        data: [
                            { value: value2, name: '正确人数' },
                            { value: value1, name: '错误人数' },
                        ],
                        itemStyle: {
                            borderWidth: this.graphicRatio(5),
                            borderColor: '#fff',
                        }
                    }
                ]
            });
            window.addEventListener("resize", () => {
                myChart.resize();
            });
        },
        //书写习惯-握笔姿势
        hold_position_graph(value1, value2) {
            this.$echarts.init(document.getElementById("hold_position_graph")).dispose();
            let myChart = this.$echarts.init(
                document.getElementById("hold_position_graph")
            );
            myChart.setOption({
                color: ['#dc91e0', '#fc918e'],
                title: {
                    text: '握笔姿势',
                    top: '4%',
                    left: 'center',
                    textStyle: {
                        fontSize: this.graphicRatio(14),
                    },
                },
                legend: {
                    top: '12%',
                    left: 'center',
                    itemWidth: this.graphicRatio(22),
                    itemHeight: this.graphicRatio(14),
                    textStyle: {
                        fontSize: this.graphicRatio(12),
                    }
                },
                series: [
                    {
                        name: '书写习惯-握笔姿势',
                        type: 'pie',
                        radius: '50%',
                        label: {
                            formatter: "{b} {c} 人",
                            fontSize: this.graphicRatio(14),
                        },
                        data: [
                            { value: value2, name: '正确人数' },
                            { value: value1, name: '错误人数' },
                        ],
                        itemStyle: {
                            borderWidth: this.graphicRatio(5),
                            borderColor: '#fff',
                        }
                    }
                ]
            });
            window.addEventListener("resize", () => {
                myChart.resize();
            });
        },
        //与上学期对比
        Term_comparison_graph(value1) {
            this.$echarts.init(document.getElementById("Term_comparison_graph")).dispose();
            let myChart = this.$echarts.init(
                document.getElementById("Term_comparison_graph")
            );
            myChart.setOption({
                grid: {
                    top: '30%',
                    left: '4%',
                    right: '4%',
                    bottom: '2%',
                    containLabel: true,
                },
                tooltip: {
                    trigger: 'axis',
                    confine: true,
                    padding: this.graphicRatio(5),
                    textStyle: {
                        fontSize: this.graphicRatio(14),
                    },
                },
                legend: {
                    show: true,
                    icon: 'rect',
                    orient: 'horizontal',
                    top: '6%',
                    y: 'center',
                    itemWidth: this.graphicRatio(22),
                    itemHeight: this.graphicRatio(14),
                    textStyle: {
                        fontSize: this.graphicRatio(12),
                    },
                    data: [
                        { name: '本学期', icon: 'circle' },
                    ],
                },
                xAxis: [
                    {
                        splitLine: {
                            show: false,
                        },
                        axisTick: {
                            show: true,
                            lineStyle: {
                                color: '#dddddd'
                            },
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#dddddd'
                            },
                        },
                        axisLabel: {
                            show: true,
                            fontSize: this.graphicRatio(14),
                            color: '#555555',
                            // interval: 'auto',
                        },
                        type: 'category',
                        data: ["优秀+", "优秀-", "良好", "待提升"],
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        axisLabel: {
                            show: false,
                        },
                        axisLine: {
                            show: false,
                        },
                        splitLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                    },
                    {
                        axisLabel: {
                            show: false,
                        },
                        axisLine: {
                            show: false,
                        },
                        splitLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                    },
                ],
                series: [
                    {
                        name: '本学期',
                        type: 'pictorialBar',
                        barGap: '0%',
                        barWidth: this.graphicRatio(60),
                        symbol: "path://M12.000,-0.000 C12.000,-0.000 16.074,60.121 22.731,60.121 C26.173,60.121 -3.234,60.121 0.511,60.121 C7.072,60.121 12.000,-0.000 12.000,-0.000 Z",
                        // barGap:10,
                        color: '#247FFF',
                        label: {
                            show: true,
                            position: 'top',
                            fontSize: this.graphicRatio(14),
                            formatter: function (params) {
                                return params.value + '%'
                            },
                        },
                        data: value1,
                        itemStyle: {
                            color: new this.$echarts.graphic.LinearGradient(
                                0,
                                1,
                                0,
                                0,
                                [
                                    {
                                        offset: 0,
                                        color: 'rgba(49, 232, 255, 0.68)', // 0% 处的颜色
                                    },
                                    {
                                        offset: 0.4,
                                        color: 'rgba(149, 231, 212, 1)', // 100% 处的颜色
                                    },
                                ],
                                false
                            ),
                        },
                        emphasis: {
                            disabled: true,
                            focus: 'none',
                        },
                    },
                ],
            });
            window.addEventListener("resize", () => {
                myChart.resize();
            });
        },
        //一级维度成绩
        dimension_graph(value, maxValue) {
            this.$echarts.init(document.getElementById("dimension_graph")).dispose();
            let myChart = this.$echarts.init(
                document.getElementById("dimension_graph")
            );
            let splitColor = "#fff";
            myChart.setOption({
                tooltip: {
                    show: true,
                    showContent: true,
                    textStyle: {
                        fontSize: this.graphicRatio(12),
                    },
                },
                radar: {
                    center: ["50%", "50%"], // 外圆的位置
                    radius: "60%",
                    axisName: {
                        color: "#033333",
                        fontSize: this.graphicRatio(14),
                        fontWeight: 400,
                        fontFamily: "PingFangSC-Regular,PingFang SC",
                        fontStyle: "normal",
                    },
                    indicator: [{ name: '章法占格', max: maxValue },
                    { name: '结构组合', max: maxValue },
                    { name: '笔画形态', max: maxValue },
                    { name: '作品完整度', max: maxValue },],
                    splitArea: {
                        // 坐标轴在 grid 区域中的分隔区域，默认不显示。
                        show: true,
                        areaStyle: {
                            // 分隔区域的样式设置。
                            color: ["RGBA(228, 229, 235, 1)"], // 分隔区域颜色。分隔区域会按数组中颜色的顺序依次循环设置颜色。默认是一个深浅的间隔色。
                        },
                    },
                    axisLine: {
                        // 指向外圈文本的分隔线样式
                        lineStyle: {
                            color: splitColor,
                        },
                    },
                    splitLine: {
                        lineStyle: {
                            type: "solid",
                            color: splitColor, // 分隔线颜色
                            width: this.graphicRatio(1), // 分隔线线宽
                        },
                    },
                },
                series: [{
                    type: "radar",
                    symbolSize: this.graphicRatio(10),
                    symbol: "none",
                    itemStyle: {
                        borderColor: "FFD900",
                        // color: "#fff",
                        borderWidth: this.graphicRatio(0.2),
                    },
                    lineStyle: {
                        width: this.graphicRatio(1),
                        color: '#9b95f7',
                    },
                    data: [{
                        name: '前侧',
                        value: value,
                        label: {
                            fontSize: this.graphicRatio(14),
                            formatter: '{value}%'
                        },
                        areaStyle: {
                            color: "rgba(155, 149, 247, 1)"
                        },
                    },],
                },
                ],

            });
            window.addEventListener("resize", () => {
                myChart.resize();
            });
        },
        // 切换报告类型
        reportChange(val) {
            this.reportType = val;
            if (val == 7) {
                this.weekParticipation();
            } else {
                // 报告code:LZ3-C-00前测 LZ3-C-01后测
                let codes = {
                    4: 'LZ3-C-00',
                    5: 'LZ3-C-01'
                }
                this.reportCode = codes[val];
                this.getIsNewDIm();
            }
        },
        // 获取月测类型
        async monthParticipation() {
            let data = {
                schoolId: this.$route.query.schoolId,
                termKey: this.termValue,
                examType: 4,
                classId: this.gradeId,
            }
            let resData = await this.$Api.Report.monthParticipation(data);
            this.monthList = resData.data;
            if (resData.data.length > 0) {
                this.isEmpty = false;
                if (this.monthVal) {

                } else {
                    this.monthVal = resData.data[0].id;
                    this.monthName = resData.data[0].name;
                }
            } else {
                this.isEmpty = true
            }


        },
        // 获取周测类型
        async weekParticipation() {
            this.weekList = [];
            this.weekValue = '';
            let that = this;
            let data = {
                schoolId: this.$route.query.schoolId,
                termKey: this.termValue,
                examType: 5,
                classId: this.gradeId,
            }
            let resData = await this.$Api.Report.getparticipationWeek(data);
            if (resData.data.length != 0) {
                this.weekList = resData.data;
                this.weekValue = this.weekList[0].id;
                this.weekName = this.weekList[0].name;
                this.isEmpty = false;
            } else {
                this.isEmpty = true;
            }
        },
        // 切换月测名称
        monthTypeChange(val) {
            this.monthVal = val;
            this.monthName = this.monthList.find(item => {
                return item.id == val;
            }).name;
            this.month_report()
        },
        // 切换周测名称
        weekValTypeChange(val) {
            this.weekVal = val;
            this.weekName = this.weekList.find(item => {
                return item.id == val;
            }).name;
        },
        // 本班单项成绩等级占比
        async monthLevelStuNumGroup() {
            await this.monthParticipation();
            let data = {
                schoolId: this.$route.query.schoolId,
                termKey: this.termValue,
                testId: this.monthVal,
                examType: 4,
                classId: this.gradeId,
            }
            let resData = this.table_type == 1 ? await this.$Api.Report.monthLevelStuNumGroup(data) : await this.$Api.Report.getlevelStuNumGroupV2_month(data);
            resData.data.map(item => {
                item.classPropRate = item.classProp + '%';
                item.gradePropRate = item.gradeProp + '%';
                if (this.table_type == 1) {
                    item.integrationLevel = item.integrationLevel == 1 ? '待提升' : item.integrationLevel == 2 ? '良好' : item.integrationLevel == 3 ? '优秀' : '优秀+'
                } else {
                    item.integrationLevel = item.integrationLevel == 1 ? '待提升' : item.integrationLevel == 2 ? '合格' : item.integrationLevel == 3 ? '良好' : '优秀'
                }

            })
            this.month_Data = resData.data.reverse();
            // 计算结论1  本班学生综合成绩来看,平均成绩xx等级的学生居多, 优秀率为xx%, xx年级优秀率
            let maxClassProp = 0;
            let maxClassProp2 = 0;
            let maxClassLevel = null;
            let gradeProp = 0;
            let arr = []
            this.month_Data.map((item, index) => {
                let obj = {
                    value: item.classProp,
                    name: item.integrationLevel
                }
                arr.push(obj);
                if (item.classProp > 0) {
                    if (item.classProp > maxClassProp) {
                        maxClassProp = item.classProp;
                        maxClassLevel = item.integrationLevel;
                        // gradeCompare = item.classProp > item.gradeProp ? '高于' : item.classProp < item.gradeProp ? '低于' : '等于'
                    } else if (item.classProp == maxClassProp) {
                        maxClassLevel += item.integrationLevel
                    }
                };
                if (this.table_type == 1) {
                    if (item.integrationLevel == '优秀' || item.integrationLevel == '优秀+') {
                        maxClassProp2 += item.classProp;
                        gradeProp += item.gradeProp;
                    }

                } else {
                    if (item.integrationLevel == '良好' || item.integrationLevel == '优秀') {
                        maxClassProp2 += item.classProp;
                        gradeProp += item.gradeProp;
                    }
                }

            })
            this.maxClassProp = maxClassProp2;
            this.maxgradeProp = gradeProp;
            this.maxClassLevel = maxClassLevel;
            this.gradeCompare = maxClassProp2 > gradeProp ? '高于' : maxClassProp2 < gradeProp ? '低于' : '等于';
            this.month_class_proportion_graph(arr);
        },
        // 本班学生测评等级列表
        async monthClassStuScoreList() {
            await this.monthParticipation();
            let data = {
                schoolId: this.$route.query.schoolId,
                termKey: this.termValue,
                testId: this.monthVal,
                examType: 4,
                classId: this.gradeId,
            }
            let resData = this.table_type == 1 ? await this.$Api.Report.monthClassStuScoreList(data) : await this.$Api.Report.getclassStuScoreListV2_month(data);
            this.month_student_list_Data = resData.data
        },
        // 本班单项成绩等级占比饼图
        month_class_proportion_graph(data) {
            this.$echarts.init(document.getElementById("month_class_proportion")).dispose();
            let myChart = this.$echarts.init(
                document.getElementById("month_class_proportion")
            );
            myChart.setOption({
                // color: ['#48c5ec', '#72d07e'],
                legend: {
                    top: '8%',
                    left: 'center',
                    itemWidth: this.graphicRatio(22),
                    itemHeight: this.graphicRatio(14),
                    textStyle: {
                        fontSize: this.graphicRatio(12),
                    }
                },
                series: [
                    {
                        // name: '书写习惯-坐姿',
                        type: 'pie',
                        radius: '50%',
                        label: {
                            formatter: "{b} {c} %",
                            fontSize: this.graphicRatio(14),
                        },
                        data,
                        itemStyle: {
                            borderWidth: this.graphicRatio(5),
                            borderColor: '#fff',
                        }
                    }
                ]
            });
            window.addEventListener("resize", () => {
                myChart.resize();
            });
        },
        // AI测评维度成绩
        async monthAI() {
            await this.monthParticipation();
            let data = {
                schoolId: this.$route.query.schoolId,
                termKey: this.termValue,
                testId: this.monthVal,
                examType: 4,
                classId: this.gradeId,
            }
            let resData = this.table_type == 1 ? await this.$Api.Report.monthAI(data) : await this.$Api.Report.getaiScoreV2_month(data);
            if (this.table_type == 1) {
                if (resData.data.class.length == 0 || !resData.data.class[0]) {
                    this.$refs.singleSubjectAvg_tea_month_old.receiveData(this.AI_Data, [0, 0, 0, 0], 100)
                } else {
                    let obj = {
                        name: this.gradeValue,
                        consistenceScore: resData.data.class[0].consistenceScore,
                        neatScore: resData.data.class[0].neatScore,
                        likelyScore: resData.data.class[0].likelyScore,
                        completeScore: resData.data.class[0].completeScore,
                        consistenceScore_type: this.judge_type_icon(resData.data.class[0].consistenceScore, resData.data.grade[0].consistenceScore),
                        neatScore_type: this.judge_type_icon(resData.data.class[0].neatScore, resData.data.grade[0].neatScore),
                        likelyScore_type: this.judge_type_icon(resData.data.class[0].likelyScore, resData.data.grade[0].likelyScore),
                        completeScore_type: this.judge_type_icon(resData.data.class[0].completeScore, resData.data.grade[0].completeScore),
                        consistenceScore_average: this.average(resData.data.class[0].consistenceScore, resData.data.grade[0].consistenceScore),
                        neatScore_average: this.average(resData.data.class[0].neatScore, resData.data.grade[0].neatScore),
                        likelyScore_average: this.average(resData.data.class[0].likelyScore, resData.data.grade[0].likelyScore),
                        completeScore_average: this.average(resData.data.class[0].completeScore, resData.data.grade[0].completeScore),
                        consistenceScore_level: this.judgeLevel(resData.data.class[0].consistenceScore),
                        neatScore_level: this.judgeLevel(resData.data.class[0].neatScore),
                        likelyScore_level: this.judgeLevel(resData.data.class[0].likelyScore),
                        completeScore_level: this.judgeLevel(resData.data.class[0].completeScore),
                    }
                    let obj2 = {
                        name: '年级均值',
                        consistenceScore: resData.data.grade[0].consistenceScore,
                        neatScore: resData.data.grade[0].neatScore,
                        likelyScore: resData.data.grade[0].likelyScore,
                        completeScore: resData.data.grade[0].completeScore,
                        consistenceScore_type: '',
                        neatScore_type: '',
                        likelyScore_type: '',
                        completeScore_type: ''
                    };
                    this.AI_Data = [obj, obj2];
                    let arr = [resData.data.class[0].consistenceScore, resData.data.class[0].neatScore, resData.data.class[0].likelyScore, resData.data.class[0].completeScore]
                    this.$refs.singleSubjectAvg_tea_month_old.receiveData(this.AI_Data, arr, 100)
                }
            } else {
                if (resData.data.class.length == 0 || !resData.data.class[0]) {
                    this.$refs.singleSubjectAvg_tea_month_new.receiveData(this.AI_Data, [0, 0, 0, 0, 0, 0, 0, 0], 100)
                } else {
                    let data1 = resData.data.class[0];
                    data1.name = this.gradeValue;
                    data1.centerPos_type = this.judge_type_icon(resData.data.class[0].centerPos, resData.data.grade[0].centerPos);
                    data1.gridSize_type = this.judge_type_icon(resData.data.class[0].gridSize, resData.data.grade[0].gridSize);
                    data1.glyphGrav_type = this.judge_type_icon(resData.data.class[0].glyphGrav, resData.data.grade[0].glyphGrav);
                    data1.comboRel_type = this.judge_type_icon(resData.data.class[0].comboRel, resData.data.grade[0].comboRel);
                    data1.rewindRel_type = this.judge_type_icon(resData.data.class[0].rewindRel, resData.data.grade[0].rewindRel);
                    data1.multiDist_type = this.judge_type_icon(resData.data.class[0].multiDist, resData.data.grade[0].multiDist);
                    data1.strokeRel_type = this.judge_type_icon(resData.data.class[0].strokeRel, resData.data.grade[0].strokeRel);
                    data1.integrity_type = this.judge_type_icon(resData.data.class[0].integrity, resData.data.grade[0].integrity);

                    data1.centerPos_average = this.average(resData.data.class[0].centerPos, resData.data.grade[0].centerPos);
                    data1.gridSize_average = this.average(resData.data.class[0].gridSize, resData.data.grade[0].gridSize);
                    data1.glyphGrav_average = this.average(resData.data.class[0].glyphGrav, resData.data.grade[0].glyphGrav);
                    data1.comboRel_average = this.average(resData.data.class[0].comboRel, resData.data.grade[0].comboRel);
                    data1.rewindRel_average = this.average(resData.data.class[0].rewindRel, resData.data.grade[0].rewindRel);
                    data1.multiDist_average = this.average(resData.data.class[0].multiDist, resData.data.grade[0].multiDist);
                    data1.strokeRel_average = this.average(resData.data.class[0].strokeRel, resData.data.grade[0].strokeRel);
                    data1.integrity_average = this.average(resData.data.class[0].integrity, resData.data.grade[0].integrity);
                    data1.centerPos_average = this.average(resData.data.class[0].centerPos, resData.data.grade[0].centerPos);

                    data1.centerPos_level = this.judgeLevel(resData.data.class[0].centerPos);
                    data1.gridSize_level = this.judgeLevel(resData.data.class[0].gridSize);
                    data1.glyphGrav_level = this.judgeLevel(resData.data.class[0].glyphGrav);
                    data1.comboRel_level = this.judgeLevel(resData.data.class[0].comboRel);
                    data1.rewindRel_level = this.judgeLevel(resData.data.class[0].rewindRel);
                    data1.multiDist_level = this.judgeLevel(resData.data.class[0].multiDist);
                    data1.strokeRel_level = this.judgeLevel(resData.data.class[0].strokeRel);
                    data1.integrity_level = this.judgeLevel(resData.data.class[0].integrity);
                    let data2 = resData.data.grade[0];
                    data2.name = '年级均值';
                    data2.centerPos_type = '';
                    data2.gridSize_type = '';
                    data2.glyphGrav_type = '';
                    data2.comboRel_type = '';
                    data2.rewindRel_type = '';
                    data2.multiDist_type = '';
                    data2.strokeRel_type = '';
                    data2.integrity_type = '';
                    this.AI_Data = [data1, data2];
                    let arr = [
                        resData.data.class[0].centerPos,
                        resData.data.class[0].gridSize,
                        resData.data.class[0].glyphGrav,
                        resData.data.class[0].comboRel,
                        resData.data.class[0].rewindRel,
                        resData.data.class[0].multiDist,
                        resData.data.class[0].strokeRel,
                        resData.data.class[0].integrity
                    ]
                    this.$refs.singleSubjectAvg_tea_month_new.receiveData(this.AI_Data, arr, 100)
                };

            }
        },
        // 月测报告请求集合
        month_report() {
            // 整体情况
            this.getparticipation();
            // 本班单项成绩等级占比
            this.monthLevelStuNumGroup();
            // // 本班学生测评等级列表
            this.monthClassStuScoreList();
            // // AI测评维度成绩
            this.monthAI();
            // // 优秀+作品
            this.getlevel4WorkPage();
            if (this.table_type == 2) {
                //本班单项成绩等级占比
                this.getstudentLvRatioCompareTV2_month();
            }
        },
        hide_school() {
            let type = this.schoolIdArr.includes(this.$route.query.schoolId);
            if (this.schoolIdArr.includes(this.$route.query.schoolId) && this.termValue == '20232') {
                type = true;
            } else {
                type = false;
            }
            return type;
        },
        async getIsNewDIm() {
            let type = this.reportValue == 4 ? 1 : this.reportValue == 5 ? 2 : 4;
            let data = {
                termKey: this.termValue,
                schoolId: this.$route.query.schoolId,
                examType: type
            }
            let resData = await this.$Api.DataScreen.getIsNewDIm(data);
            this.table_type = resData.data;
            this.gradequeryOrgInfo();
        },
        async getstudentLvRatioCompareTV2_month() {
            await this.monthParticipation();
            let data = {
                schoolId: this.$route.query.schoolId,
                termKey: this.termValue,
                testId: this.monthVal,
                examType: 4,
                classId: this.gradeId,
            };
            let res = await this.$Api.Report.getstudentLvRatioCompareTV2_month(data);
            this.$refs.singleSubjectAvg_tea_month_new.gradesPercentage(res);
        }
    },
    // 生命周期：挂载完成时（可以访问DOM元素）
    mounted() {
        //api请求
        this.getTermList();
        this.schoolName = this.$route.query.schoolName;
    }
}
</script>
<style lang='less' scoped>
@import url("./less/frontBackaAppraisal_tea.less");
</style>